:root{
--color-primary:#ea4335;
}
/*========================================*/
.cols-1>* {
max-width: 100%;
flex: 0 0 100%
}
.cols-2>* {
max-width: 50%;
flex: 0 0 50%
}
.cols-3>* {
max-width: 33.3333% !important;
flex: 0 0 33.3333%
}
.cols-4>* {
max-width: 25%;
flex: 0 0 25%
}
.cols-5>* {
max-width: 20%;
flex: 0 0 20%
}
.cols-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}
.cols-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}
.cols-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
@media (min-width:1200px) {
.cols-xl-1>* {
max-width: 100%;
flex: 0 0 100%
}
.cols-xl-2>* {
max-width: 50%;
flex: 0 0 50%
}
.cols-xl-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}
.cols-xl-4>* {
max-width: 25%;
flex: 0 0 25%
}
.cols-xl-5>* {
max-width: 20%;
flex: 0 0 20%
}
.cols-xl-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}
.cols-xl-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}
.cols-xl-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}
@media (min-width:1600px) {
.cols-xxl-1>* {
max-width: 100%;
flex: 0 0 100%
}
.cols-xxl-2>* {
max-width: 50%;
flex: 0 0 50%
}
.cols-xxl-3>* {
max-width: 33.3333%;
flex: 0 0 33.3333%
}
.cols-xxl-4>* {
max-width: 25%;
flex: 0 0 25%
}
.cols-xxl-5>* {
max-width: 20%;
flex: 0 0 20%
}
.cols-xxl-6>* {
max-width: 16.6667%;
flex: 0 0 16.6667%
}
.cols-xxl-7>* {
max-width: 14.2857%;
flex: 0 0 14.2857%
}
.cols-xxl-8>* {
max-width: 12.5%;
flex: 0 0 12.5%
}
}
@media (min-width:1200px) {
.col-xl-5col {
position: relative;
flex: 0 0 20%;
max-width: 20%;
padding-right: 10px;
padding-left: 10px
}
.col-xl-5col2 {
position: relative;
flex: 0 0 40%;
max-width: 40%;
padding-right: 10px;
padding-left: 10px
}
.col-xl-5col4 {
position: relative;
flex: 0 0 80%;
max-width: 80%;
padding-right: 10px;
padding-left: 10px
}
}
@media screen and (max-width: 991px) {
.order2 {
-webkit-box-ordinal-group: 3 !important;
-ms-flex-order: 2 !important;
order: 2 !important;
}
.order1 {
-webkit-box-ordinal-group: 2 !important;
-ms-flex-order: 1 !important;
order: 1 !important;
}  
}
/*========================================*/
.tx-12{
font-size:12px;
}
.tx-13{
font-size:13px;
}
.tx-14{
font-size:14px;
}
.tx-15{
font-size:15px;
}
.tx-16{
font-size:16px;
}
.tx-18{
font-size:18px;
}
.tx-uppercase{
text-transform: uppercase;
}
.tx-left{
text-align: left;
}
.tx-center{
text-align: center;
}
.tx-right{
text-align: right;
}
/*========================================*/
.section-gap-sm{
padding: 30px 0px;
}
.section-gap-md{
padding: 60px 0px;
}
.section-gap-large {
padding: 80px 0px;
}
.section-gap-medium {
padding: 60px 0px;
}
.section-gap-small {
padding: 30px 0px;
}
.sectiontop-gap-large {
padding-top: 80px;
}
.sectiontop-gap-medium {
padding-top: 60px;
}
.sectiontop-gap-small {
padding-top: 30px;
}
.sectionbottom-gap-large {
padding-bottom: 80px;
}
.sectionbottom-gap-medium {
padding-bottom: 60px;
}
.sectionbottom-gap-small {
padding-bottom: 30px;
}
/*========================================*/
.section-title h2{
font-size: 32px;
line-height: 42px;
}
.title-white h1, .title-white h2, .title-white h3, .title-white h4, .title-white h5, .title-white h6{
color: #ffffff;
}
/*========================================*/
.form-group {
position: relative;
width: 100%;
}
.country-input .form-contact {
padding-left: 50px;
}
.country-code {
align-items: center;
display: flex;
height: 55px;
justify-content: center;
left: 0;
position: absolute;
top: 0;
width: 50px;
}
.form-group.otp-input > div{
display: flex;
align-items: center;
gap: 10px;
}
.form-group.otp-input input{
text-align: center;
width: 100% !important;
}
.form-group-radio{
width: 100%;
display: flex;
align-items: center;
gap: 15px;
}
.radioBox{
display: flex;
align-items: center;
}
.radioBox input[type=radio] {
position: absolute;
opacity: 0;
}
.radioBox input[type=radio] + .radio-label:before {
content: "";
background: #f4f4f4;
border-radius: 100%;
border: 2px solid var(--color-primary);
display: inline-block;
width: 1.4em;
height: 1.4em;
position: relative;
margin-right: 0.5em;
vertical-align: middle;
top: -0.1em;
cursor: pointer;
text-align: center;
transition: all 250ms ease;
}
.radioBox input[type=radio]:checked + .radio-label:before {
background-color: var(--color-primary);
box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radioBox input[type=radio]:focus + .radio-label:before {
outline: none;
border-color: var(--color-primary);
}
.radioBox input[type=radio]:disabled + .radio-label:before {
box-shadow: inset 0 0 0 4px #f4f4f4;
border-color: #b4b4b4;
background: #b4b4b4;
}
.radioBox input[type=radio] + .radio-label:empty:before {
margin-right: 0;
}
.radioGruop{
position: relative;
}
/*========================================================*/
.amnitiesbox{
border-radius: 8px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 16px;
width: 100%;
height: 200px;
position: relative;
padding: 15px;
background: #ffffff;
border: 1px solid #eee;
}
.amnitiesbox .box-icon{
background: #f5f5f5;
}
.amnitiesbox .box-icon img{
width: 42px;
height: 42px;
}
.amnitiesbox h6{
font-size: 14px;
line-height: 24px;
}
/*========================================================*/
.searchlocationlist{
position: absolute;
background: white;
padding: 20px;
z-index: 9999;
width: 21%;
border: 1px solid #ddd;
margin-left: -20px;
margin-top: 10px;

}

/*========================================================*/
.topheader{
width: 100%;
position: relative;
background: #2e2e2e;
padding: 7px;
}
.topheader .container, .topheader .container-fluid{
display: flex;
align-items: center;
}
.topheader-center,
.topheader-left,
.topheader-right {
display: flex;
align-items: center;
}
.topheader-left, .topheader-right {
flex: 1 1;
}
.leftHeaderlist {
align-items:center;
display:flex;
gap:20px;
list-style:none;
margin:0;
padding:0
}
.leftHeaderlist li {
display:inline-block;
position:relative;
font-size: 15px;
}
.leftHeaderlist li a {
color:#fff;
display: flex;
align-items: center;
gap: 5px;
}
.rightHeaderlist {
align-items:center;
display:flex;
gap:20px;
list-style:none;
margin:0;
padding:0
}
.rightHeaderlist li {
display:inline-block;
position:relative
}
.rightHeaderlist li a {
color:#fff;
}
@media screen and (max-width: 991px) {
.topheader .container, .topheader .container-fluid{
display: flex;
align-items: center;
flex-direction: column;
}
.topheader{
padding: 7px 0px;
}
.leftHeaderlist li {

font-size: 13px;
}
.searchlocationlist {
    position: absolute;
    background: white;
    padding: 20px;
    z-index: 9999;
    width: 91%;
    border: 1px solid #ddd;
    margin-left: 1px;
    margin-top: 10px;
}
}
/*========================================================*/
.wrap-search-link ul{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 5px;
flex-wrap: wrap;
}
.wrap-search-link ul li{
cursor: pointer;
display: inline-block;
background: #f5f5f5;
border-radius: 50px;
padding: 1px 10px;
font-size: 14px;
}
.filterbox{
background-color: #fff;
border: 1px solid #e4e4e4;
font-size: 16px;
line-height: 26px;
border-radius: 8px;
padding: 10px 16px 10px 16px;
cursor: pointer;
display: none;
}
@media screen and (max-width: 991px) {
.filterbox{
display: block;
}
}
/*========================================================*/
.agent-details .box-avatar{
display: flex;
align-items: center;
flex-wrap: nowrap !important;
}
.agent-details .box-avatar .info{
flex: 1;
}
/*========================================================*/
.agentbox{
width: 100%;
position: relative;
margin-bottom: 20px;
}
.agentbox .agentboxBanner{
width: 100%;
position: relative;
height: 500px;
background: #f5f5f5;
border-radius: 16px;
overflow: hidden;
}
.agentboxBanner img{
width: 100%;
height: 100%;
object-fit: cover;
}
.agentboxProfile{
position: relative;
width: 100%;
position: absolute;
bottom: 0;
left: 0;
padding: 50px;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+62 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 62%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}
.agentboxProfile .agentboxProfileInner{
display: flex;
align-items: center;
gap: 15px;
color: #fff;
}
.agentboxProfile .agentboxProfileImg{
width: 125px;
height: 125px;
border: 2px solid #eee;
border-radius: 50%;
}
.agentboxProfileContent{
flex: 1;
}
.agentboxProfileContent .agentName{
font-size: 26px;
color: #fff;
margin-bottom: 5px;
line-height: 26px;
}
.aprofileinfo{
margin: 0;
padding: 0;
list-style: none;
}
.aprofileinfo li{
display: flex;
align-items: center;
margin-bottom: 15px;
}
.aprofileinfo li:last-child{
margin-bottom: 0px;
}
.aprofileinfo li a{
display: flex;
align-items: center;
gap: 10px;
}
.aprofileinfo li .box-icon {
border-radius: 8px;
background-color: #fff;
}
.aprofileinfo li .box-icon .icon {
font-size: 22px;
color: #ED2027;
}
.aprofileinfo li .content{
flex: 1;
}
.agentSocial{
margin-left: auto;
}
.agentSocial ul{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
gap: 10px;
}
.agentSocial ul li{
display: inline-block;
}
.agentSocial ul li a{
display: inline-block;
width: 32px;
height: 32px;
background: #fff;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
@media screen and (max-width: 991px) {
    .agentbox{
        margin-bottom: 0px;
    }
    .agentbox .agentboxBanner{
        border-radius: 0px;
        height: 400px;
    }
    .agentboxProfile{
        padding: 15px;
    }
    .agentboxProfileInner{
        flex-direction: column;
        justify-content: center;
       text-align: center;
    }
    .agentSocial{
        margin-left: inherit;
        }
        .agprof{
            border-radius: 0px;
        }
}
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/
/*========================================================*/